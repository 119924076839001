import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Seo from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

export default function Template({
  pageContext: { next, previous },
  data: { mdx }, // this prop will be injected by the GraphQL query below.
}) {
  //console.log(data)
  // const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body, excerpt } = mdx
  // const post = data.mdx

  const nextArticle = next && (
    <Link to={next.frontmatter.slug}>
      <strong className='text-sm uppercase text-siva no-underline inline-block'>
        PRIJAŠNJA OBJAVA
      </strong>
      <br />
      {next.frontmatter.title}
    </Link>
  )

  const prevArticle = previous && (
    <Link to={previous.frontmatter.slug}>
      <strong className='text-sm uppercase text-siva inline-block no-decoration'>
        NOVIJA OBJAVA
      </strong>
      <br />
      {previous.frontmatter.title}
    </Link>
  )

  return (
    <>
      <Seo
        title={frontmatter.title}
        description={excerpt}
        image={
          frontmatter.promoSlika
            ? frontmatter.promoSlika.childImageSharp.gatsbyImageData.images
                .fallback.src
            : null
        }
      />

      <Layout>
        {frontmatter.promoSlika ? (
          <GatsbyImage
            image={frontmatter.promoSlika.childImageSharp.gatsbyImageData}
          />
        ) : null}
        <h1 className='mt-6 mb-3 text-3xl text-heading'>{frontmatter.title}</h1>
        <p className='datum text-sm text-siva mb-6 border-b'>
          {frontmatter.date}
        </p>
        {/* <div
            className='blog-post-content'
            // dangerouslySetInnerHTML={{ __html: html }}
          /> */}
        <MDXRenderer>{body}</MDXRenderer>
        <div className='news-nav grid grid-cols-2 gap-12 mt-12 pt-8 border-t border-siva'>
          <div>{prevArticle}</div>
          <div>{nextArticle}</div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      excerpt(truncate: true)
      frontmatter {
        date(formatString: "dddd, DD. MMMM, YYYY.", locale: "hr")
        slug
        title
        promoSlika {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
